<template>
  <div>
    <h1>Listado de Inquietudes</h1>
    <v-spacer />
    <TableCrudProfesor
      @send-data="receiveData"
      :headers="headersVrae"
      :nameTitle="'Inquietudes Docentes'"
      :inquietudes="inquietudes"
      :profe="true"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import TableCrudProfesor from "@/components/TableCrudProfesor.vue";
export default {
  name: "Funcionarios",
  components: {
    TableCrudProfesor
  },
  data() {
    return {
      loading: "cargando",
      itemType: "Proceso",
      headersVrae: [
        { text: "Centro de Costo", value: "ccNombre" },
        { text: "Asignatura Impartida", value: "nombreAsignatura" },
        { text: "Código asignatura", value: "codigoAsignatura" },
        { text: "Fecha de Ingreso", value: "createdAt" },
        { text: "Acciones", value: "acciones", sortable: false }
      ],
      inquietudes: [],
      inquietudesProtocolo: [],
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") }
      }
    };
  },

  created() {
    this.axios
      .get(
        process.env.VUE_APP_BASE_URL +
          "/solicitudProfesor/getAllByProfesorId?id=" +
          this.$session.getAll().idUser,
        this.auth
      )
      .then(response => {
        this.inquietudes = response.data;
      })
      .catch(error => {
        alert(error);
      });
  },
  methods: {
    receiveData(event) {
      this.inquietudes = event;
    }
  }
};
</script>

<style>
.c-orange {
  color: rgb(255, 153, 0);
}
</style>