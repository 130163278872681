<template>
  <v-app >
    <router-view></router-view>
  </v-app>
</template>

<script>
import Layout from './components/layout component/Layout'
export default {
  name: 'App',

  components: {
    Layout
  },

  created(){
      this.$session.set("resize", 0);
  },

  data: () => ({
    //
  }),
};
</script>
