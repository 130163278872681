<template>
  <v-data-table
    item-key="nombre"
    :fixed-header="fixed"
    :height="height"
    :headers="headers"
    :items="inquietudes"
    :items-per-page="10"
    :search="search"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{nameTitle}}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>

        <v-dialog v-model="dialog" max-width="700px">
          <v-card>
            <v-card-title>
              <span class="headline">Detalle de la inquietud</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      readonly
                      outlined
                      dense
                      persistent-hint
                      v-model="editedItem.alumno.nombre"
                      label="Nombre"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      readonly
                      outlined
                      dense
                      persistent-hint
                      v-model="editedItem.alumno.apellido1"
                      label="Apellido Paterno"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      readonly
                      outlined
                      dense
                      persistent-hint
                      v-model="editedItem.alumno.apellido2"
                      label="Apellido Materno"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      readonly
                      outlined
                      dense
                      persistent-hint
                      v-model="editedItem.alumno.carrera.nombre"
                      label="Carrera"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      readonly
                      outlined
                      dense
                      persistent-hint
                      v-model="editedItem.inquietud"
                      label="Tipo de inquietud"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      readonly
                      persistent-hint
                      outlined
                      counter
                      no-resize
                      label="Detalle"
                      v-model="editedItem.detalle"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog2" max-width="700px">
          <v-card>
            <v-card-title>
              <span class="headline">Detalle de inquietud de protocolo académico</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      readonly
                      outlined
                      dense
                      persistent-hint
                      v-model="editedItem.alumno.nombre"
                      label="Nombre"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      readonly
                      outlined
                      dense
                      persistent-hint
                      v-model="editedItem.alumno.apellido1"
                      label="Apellido Paterno"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      readonly
                      outlined
                      dense
                      persistent-hint
                      v-model="editedItem.alumno.apellido2"
                      label="Apellido Materno"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      readonly
                      outlined
                      dense
                      persistent-hint
                      v-model="editedItem.alumno.carrera.nombre"
                      label="Carrera"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      readonly
                      outlined
                      dense
                      persistent-hint
                      v-model="editedItem.nombreAsignatura"
                      label="Asignatura"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      readonly
                      outlined
                      dense
                      persistent-hint
                      v-model="editedItem.codigoAsignatura"
                      label="Código"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      readonly
                      persistent-hint
                      outlined
                      counter
                      no-resize
                      label="Detalle"
                      v-model="editedItem.detalle"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog2 = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-text-field
          outlined
          class="mx-1"
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          hide-details
          dense
        ></v-text-field>
      </v-toolbar>
    </template>
    <template v-slot:item.acciones="{ item }">
      <v-icon class="mr-2" v-if="vrae" @click="showDetailItem(item)">mdi-eye</v-icon>
      <v-icon class="mr-2" v-if="!vrae" @click="showDetailItem2(item)">mdi-eye</v-icon>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    vrae: Boolean,
    headers: Array,
    inquietudes: Array,
    nameTitle: String
  },
  data: () => ({
    dialog: false,
    dialog2: false,
    search: "",
    height: 500,
    fixed: true,
    editedItem: {
      nombre: "",
      alumno: {
        nombre: "",
        apellido1: "",
        apellido2: "",
        nombreCompleto: "",
        carrera: {
          nombre: ""
        }
      }
    }
  }),

  methods: {
    showDetailItem(item) {
      //TODO: Cambiar busqueda por algo unico
      this.editedItem = item;
      this.dialog = true;
    },

    showDetailItem2(item) {
      //TODO: Cambiar busqueda por algo unico
      this.editedItem = item;
      this.dialog2 = true;
    }
  }
};
</script>