<template>
  <v-data-table
    v-model="selected"
    item-key="nombre"
    :fixed-header="fixed"
    :height="height"
    :headers="headers"
    :items="inquietudes"
    :items-per-page="10"
    :search="search"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{nameTitle}}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>

        <v-dialog v-model="dialog" max-width="700px">
          <v-card>
            <v-card-title>
              <span class="headline">Detalle de la inquietud</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      readonly
                      outlined
                      dense
                      persistent-hint
                      v-model="editedItem.nombreAsignatura"
                      label="Asignatura Impartida"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      readonly
                      outlined
                      dense
                      persistent-hint
                      v-model="editedItem.codigoAsignatura"
                      label="Código de Asignatura"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      readonly
                      outlined
                      dense
                      persistent-hint
                      v-model="editedItem.ccNombre"
                      label="Centro de Costo"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-textarea
                      readonly
                      persistent-hint
                      outlined
                      counter
                      no-resize
                      label="Detalle"
                      v-model="editedItem.detalle"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close()">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog2" max-width="700px">
          <v-card>
            <v-card-title>
              <span class="headline">Detalle de inquietud Docente</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      readonly
                      outlined
                      dense
                      persistent-hint
                      label="Nombre Docente"
                      :value="profesor.nombre + ' ' +profesor.apellido1 + ' ' +profesor.apellido2 "
                    ></v-text-field>
                    <v-text-field
                      readonly
                      outlined
                      dense
                      persistent-hint
                      label="Correo Docente"
                      v-model="profesor.email"
                    ></v-text-field>
     
                    <v-text-field
                      readonly
                      outlined
                      dense
                      persistent-hint
                      v-model="editedItem.nombreAsignatura"
                      label="Asignatura Impartida"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      readonly
                      outlined
                      dense
                      persistent-hint
                      v-model="editedItem.codigoAsignatura"
                      label="Código de Asignatura impartida"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      readonly
                      outlined
                      dense
                      persistent-hint
                      v-model="editedItem.ccNombre"
                      label="Centro de Costo de asignatura"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-textarea
                      readonly
                      persistent-hint
                      outlined
                      counter
                      no-resize
                      label="Detalle"
                      v-model="editedItem.detalle"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog2 = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-text-field
          outlined
          class="mx-1"
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          hide-details
          dense
        ></v-text-field>
      </v-toolbar>
    </template>
    <template v-slot:item.acciones="{ item }">
      <v-icon class="mr-2" v-if="profe" @click="showDetailItem(item)">mdi-eye</v-icon>
      <v-icon class="mr-2" v-if="!profe" @click="showDetailItem2(item)">mdi-eye</v-icon>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    profe: Boolean,
    headers: Array,
    inquietudes: Array,
    nameTitle: String
  },
  data: () => ({
    funcionariosLocal: [],
    dialog: false,
    dialog2: false,
    mailDialog: false,
    deleteSelected: false,
    search: "",
    height: 475,
    fixed: true,
    selected: [],
    editedIndex: -1,
    editedItem: {
      nombre: "",
      puesto: "",
      unidad: "",
      planta: "",
      grado: ""
    },
    profesor:{
      nombre: "",
      email: ""
    }
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nuevo Funcionario"
        : "Editar Funcionario";
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    editedIndex() {}
  },

  created() {
    //Object.assign(this.funcionariosLocal, this.inquietudes);
    this.funcionariosLocal = JSON.parse(JSON.stringify(this.inquietudes));
  },

  methods: {
    initialize() {
      this.funcionariosLocal = [];
      this.sendData();
    },

    sendData() {
      this.$emit("send-data", this.funcionariosLocal);
    },

    showDetailItem(item) {
      //TODO: Cambiar busqueda por algo unico
      this.editedItem = item;
      this.dialog = true;
    },

    showDetailItem2(item) {
      //TODO: Cambiar busqueda por algo unico
      this.editedItem = item;
      this.profesor = item.profesor
      this.dialog2 = true;
    },

    bulkDelete() {
      this.selected.forEach(value => {
        var i = this.funcionariosLocal.indexOf(value);
        this.funcionariosLocal.splice(i, 1);
      });
      this.selected = [];
      this.deleteSelected = false;
      this.sendData();
    },

    deleteItem(item) {
      const index = this.funcionariosLocal.indexOf(item);
      confirm(
        "¿Esta seguro de querer eliminar al funcionario " + item.nombre + "?"
      ) && this.funcionariosLocal.splice(index, 1);
      this.sendData();
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(
          this.funcionariosLocal[this.editedIndex],
          this.editedItem
        );
      } else {
        this.funcionariosLocal.push(this.editedItem);
      }
      this.sendData();
      this.close();
    }
  }
};
</script>