<template>
    <v-footer fixed color="#394049" dark>
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} —
        <strong>Universidad de Santiago de Chile - Prorrectoría</strong>
      </v-col>
    </v-footer>
</template>
<script>
export default {
  name: "Footer"
}
</script>