<template>
  <v-container>
    <h1>Formulario de inquietud de protocolo académico</h1>
    <v-row>
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel id="datosProtocolo">
            <v-expansion-panel-header style="color:white" color="#071D49">Datos del Estudiante</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    persistent-hint
                    readonly
                    label="Nombre completo"
                    outlined
                    dense
                    :value=" alumno.nombre + ' '  + alumno.apellido1 + ' ' + alumno.apellido2"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="3">
                  <v-text-field
                    persistent-hint
                    readonly
                    label="Rut"
                    outlined
                    dense
                    v-model="alumno.run"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="3">
                  <v-text-field
                    persistent-hint
                    readonly
                    label="Ingreso"
                    outlined
                    dense
                    v-model="alumno.periodoIngreso"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="10">
                  <v-text-field
                    persistent-hint
                    readonly
                    label="Carrera"
                    outlined
                    dense
                    v-model="alumno['carrera']['nombre']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    persistent-hint
                    readonly
                    label="Código Plan de Estudio"
                    outlined
                    dense
                    v-model="alumno.codigoPlan"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    persistent-hint
                    readonly
                    label="Dirección"
                    outlined
                    dense
                    v-model="alumno.direccion"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    persistent-hint
                    readonly
                    label="Comuna"
                    outlined
                    dense
                    v-model="alumno.comuna"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-form id="formProtocolo" v-model="valid" :lazy-validation="lazy">
      <v-row>
        <v-col cols="12"></v-col>
        <v-col cols="12">
          <p>Sus datos de contacto serán enviados a funcionarios del departamento asociado a su asignatura(Jefe de Carrera, Director de Departamento, Coordinador, etc).</p>
          <p>Los campos marcados con asterisco (*) son de carácter obligatorio</p>
        </v-col>
        <v-col cols="12">
          <v-select
            return-object
            :rules="requiredRules"
            :items="asignaturas"
            item-text="nombre"
            outlined
            dense
            hint="Selecciona Nombre Asignatura"
            persistent-hint
            label="Asignaturas *"
            v-model="asignatura"
          ></v-select>
        </v-col>
      </v-row>
      <!-- ∫ -->
      <v-row>
        <v-col cols="12">
          <v-textarea
            hint="Ingresa el detalle de tu inquietud"
            :rules="requiredRules"
            persistent-hint
            outlined
            counter
            no-resize
            label="Detalle *"
            v-model="detalle"
          ></v-textarea>
        </v-col>
      </v-row>
      <!--   <v-row>
      <v-col cols="12" >
        <v-file-input
          v-model="files"
          placeholder="Selecciona un archivo"
          label="Subir Archivo"
          outlined
          prepend-icon="mdi-paperclip"
        >
          <template v-slot:selection="{ text }">
            <v-chip small label color="primary">{{ text }}</v-chip>
          </template>
        </v-file-input>
      </v-col>
      </v-row>-->
      <v-row>
        <v-col cols="12">
          <v-row justify="end">
            <v-btn
              :loading="loading"
              style="margin-bottom:30px"
              :disabled="!valid"
              color="warning"
              @click="enviarInquietud()"
              light
            >Enviar</v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      profesores: ["Mauricio Marin", "Edmundo Leiva", "Hector Antillanca"],
      asignaturas: [],
      panel: true,
      files: [],
      alumno: {},
      profesor: "",
      asignatura: "",
      detalle: "",
      deshabilitado: true,
      carrera: {},
      alumno: {
        carrera: {}
      },
      requiredRules: [v => !!v || "Este campo es requerido"],
      lazy: false,
      valid: false,
      loading: false,
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") }
      }
    };
  },

  created() {
    this.axios
      .get(
        process.env.VUE_APP_BASE_URL +
          "/alumno/getAlumno?run=" +
          this.$session.getAll().idUser,
        this.auth
      )
      .then(response => {
        this.alumno = response.data;
      })
      .catch(error => {
        alert(error);
      })
      .then(response => {
        this.axios
          .get(
            process.env.VUE_APP_BASE_URL +
              "/asignatura/getByRun?run=" +
              this.$session.getAll().idUser,
            this.auth
          )
          .then(response => {
            this.asignaturas = response.data;
          })
          .catch(error => {
            alert(error);
          });
      });
  },

  mounted() {
    var resize = this.$session.getAll().resize;
    function changeFont(element) {
      var style = window
        .getComputedStyle(element, null)
        .getPropertyValue("font-size");
      var fontSize = parseFloat(style);
      element.style.fontSize = fontSize + 2 * resize + "px";
      for (var i = 0; i < element.children.length; i++) {
        changeFont(element.children[i]);
      }
    }
    changeFont(document.getElementById("datosProtocolo"));
    changeFont(document.getElementById("formProtocolo"));
  },

  methods: {

    enviarInquietud() {
      this.loading = true;
      const solicitud = {
        detalle: this.detalle,
        profesor: this.profesor,
        codigoAsignatura: this.asignatura.codigo,
        nombreAsignatura: this.asignatura.nombre
      };
      this.axios
        .post(
          process.env.VUE_APP_BASE_URL +
            "/solicitudProtocolo/createSolicitudProtocolo?run=" +
            this.$session.getAll().idUser +
            "&email=" +
            this.$session.getAll().email,
          solicitud,
          this.auth
        )
        .then(response => {
          console.log(response.data)
          var elements = response.data
          alert("Inquietud Enviada a estos correos: " + elements.join());
          this.loading = false;
          this.$router.push("/menu");
        })
        .catch(error => {
          alert(error);
          this.loading = false;
        });
    }
  }
};
</script>