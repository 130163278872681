<template>
  <v-container class="fill-height" fluid>
    <v-app-bar fixed color="#071D49" class="nav mx-btn" height="90">
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        color="white"
        class="md-hidden"
      ></v-app-bar-nav-icon>
      <v-navigation-drawer v-model="drawer" temporary app absolute>
        <v-list flat>
          <v-list-item-group v-model="group" color="deep-orange">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="title"
                  >Hola Usuario</v-list-item-title
                >
                <v-list-item-subtitle>perfil</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item :to="{ name: 'menu' }">
              <v-list-item-title>Inicio</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-btn depressed @click="logout()" text color="black" light
                >Cerrar sesión</v-btn
              >
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <img
        class="img-fix"
        alt="Logo universidad de Santiago de Chile"
        :src="img1"
        width="140"
        height="70"
        style="margin-right: 20px"
      />

      <v-toolbar-title class="text-white">USACH TE APOYA</v-toolbar-title>

      <v-spacer></v-spacer>
      <div class="text-center">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="indigo" dark v-bind="attrs" v-on="on">
              Contacto
            </v-btn>
          </template>

          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-action>
                  <img width="100" :src="logo_usach2" alt="John" />
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>Prorrectoría</v-list-item-title>
                  <v-list-item-subtitle
                    >Contacto de Plataforma</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-list>
              <v-list-item>
                <v-list-item-action>
                  <v-icon right>mdi-email</v-icon>
                </v-list-item-action>
                <v-list-item-title>contacto.usachteapoya@usach.cl</v-list-item-title>
              </v-list-item>
            </v-list>

            <v-list-item>
              <v-list-item-action>
                <v-icon right>mdi-youtube</v-icon>
              </v-list-item-action>
              <v-list-item-title
                ><a
                  href="https://www.youtube.com/watch?v=P83ShddfsUg&feature=youtu.be"
                  target="_blank"
                  >Instructivo perfil Docente</a
                ></v-list-item-title
              >
            </v-list-item>

            <v-list-item>
              <v-list-item-action>
                <v-icon right>mdi-youtube</v-icon>
              </v-list-item-action>
              <v-list-item-title
                ><a
                  href="https://www.youtube.com/watch?v=qRxlf9WC7Zw&feature=youtu.be"
                  target="_blank"
                  >Instructivo perfil Estudiante</a
                ></v-list-item-title
              >
            </v-list-item>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn text @click="menu = false"> Cerrar </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>
      <v-btn
        @click="resizeDown()"
        dark
        icon
        title="Disminuir tamaño de letra"
        class="sm-hidden mr-1"
      >
        <v-icon>mdi-format-font-size-decrease</v-icon>
      </v-btn>

      <v-btn
        @click="resizeUp()"
        dark
        icon
        title="Aumentar tamaño de letra"
        class="sm-hidden mr-1"
      >
        <v-icon>mdi-format-font-size-increase</v-icon>
      </v-btn>
    </v-app-bar>
    <img :src="img" class="parallax" />
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="5">
        <v-card class="elevation-12">
          <v-toolbar color="#071D49" dark flat>
            <v-toolbar-title>Acceso a Usach te apoya</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-select
                return-object
                prepend-icon="mdi-library"
                outlined
                dense
                item-text="name"
                :items="opcionesTipo"
                persistent-hint
                label="Tipo de usuario"
                v-model="tipo"
              ></v-select>
              <v-text-field
                dense
                outlined
                label="Nombre de usuario (correo Usach sin extensión)"
                name="login"
                prepend-icon="mdi-account"
                type="text"
                v-model="user"
              ></v-text-field>

              <v-text-field
                outlined
                dense
                id="password"
                label="Contraseña de correo"
                name="password"
                prepend-icon="mdi-lock"
                type="password"
                v-model="pass"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#394049" @click="login()" dark>Ingresar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import img from "../../assets/depto.png";
import img1 from "../../assets/LOGO USACH.png";
import logo_usach2 from "../../assets/logo_usach2.jpg";

import jwtDecode from "jwt-decode";

export default {
  data: () => ({
    menu: false,
    img1: img1,
    logo_usach2: logo_usach2,
    img: img,
    tipo: "",
    opcionesTipo: [
      {
        name: "Estudiante",
        value: 1,
      },
      {
        name: "Docente",
        value: 3,
      },
      {
        name: "Otro",
        value: 2,
      },
    ],
    user: "",
    pass: "",
  }),

  methods: {
    resizeUp() {
      var resize = this.$session.getAll().resize;
      this.$session.set("resize", resize + 1);
      function changeFont(element) {
        var style = window
          .getComputedStyle(element, null)
          .getPropertyValue("font-size");
        var fontSize = parseFloat(style);
        element.style.fontSize = fontSize + 2 + "px";
        for (var i = 0; i < element.children.length; i++) {
          changeFont(element.children[i]);
        }
      }
      changeFont(document.getElementsByTagName("body")[0]);
    },
    resizeDown() {
      var resize = this.$session.getAll().resize;
      if (this.$session.getAll().resize > 0)
        this.$session.set("resize", resize - 1);
      function changeFont(element) {
        var style = window
          .getComputedStyle(element, null)
          .getPropertyValue("font-size");
        var fontSize = parseFloat(style);
        element.style.fontSize = fontSize - 2 + "px";
        for (var i = 0; i < element.children.length; i++) {
          changeFont(element.children[i]);
        }
      }
      changeFont(document.getElementsByTagName("body")[0]);
    },
    login() {
      var sha1 = require("sha1");
      //console.log(this.mail)
      //this.loading = true;
      const user = {
        usuario: this.user,
        tipo: this.tipo.value,
        password: sha1(this.pass),
      };
      //console.log(user);
      //console.log(user);

      this.axios
        .post(process.env.VUE_APP_BASE_URL + "/login", user)
        .then((response) => {
          //console.log(response.data);
          //console.log('haciendo la mutación')
          this.$session.start();
          this.$session.set("jwt", "Bearer " + response.data);
          //console.log(response.data);
          var Decode = jwtDecode(response.data);
          //console.log(Decode);
          //this.$session.set("nombre", Decode.nombre);
          this.$session.set("idUser", Decode.sub);
          this.$session.set("typeUser", Decode.authorities[0]);
          this.$session.set("isAuth", true);
          this.$session.set("email", Decode.email);
          this.$session.set("resize", 0);
          //this.$session.set("ccData", Decode.ccComplete);
          //this.$session.set("userType", Decode.userType);

          /*TODO:Por alguna razón no funca 
          this.showAlert("success", "ingreso correcto");
          this.$session.set("userType", Decode.userType);
          */
          //console.log(Decode.userType);
          this.$router.push({ name: "menu" });
          //this.loading = false;
        })
        .catch((error) => {
          //console.error(error.response.data);
          //this.loading = false;
          alert(error.response.data);
        });
    },
  },
};
</script>
<style scoped>
.parallax {
  position: fixed;
  z-index: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  filter: blur(0.3rem);
}
</style>