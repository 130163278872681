<template>
  <div>
    <v-row>
      <v-col cols="6">
        <h1>Listado de inquietudes</h1>
      </v-col>
      <v-col cols="6" align="end">
        <v-btn class="hidden2" id="botonCambio" color="blue" dark @click="cambiarBoton()">{{nombreBoton}}</v-btn>
        <v-btn class="hidden1" id="botonCambio" color="blue" dark @click="cambiarBoton()">Cambiar Inquietud</v-btn>
      </v-col>
    </v-row>
    <v-spacer />
    <TableFuncionarios
      v-if="vrae"
      @send-data="receiveData"
      :headers="headersVrae"
      :nameTitle="'Inquietudes de VRAE'"
      :inquietudes="inquietudes"
      :vrae="true"
    />

    <v-spacer />
    <TableFuncionarios
      v-if="!vrae"
      @send-data="receiveData"
      :nameTitle="'Inquietudes de Protocolo Académico'"
      :headers="headersAcademico"
      :inquietudes="inquietudesProtocolo"
      :vrae="false"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import TableFuncionarios from "@/components/TableCrud.vue";
export default {
  name: "Funcionarios",
  components: {
    TableFuncionarios
  },
  data() {
    return {
      vrae: true,
      loading: "cargando",
      itemType: "Proceso",
      headersVrae: [
        { text: "Inquietud", value: "inquietud" },
        { text: "Fecha de Ingreso", value: "createdAt" },
        { text: "Acciones", value: "acciones", sortable: false }
      ],

      headersAcademico: [
        { text: "Asignatura", value: "nombreAsignatura" },
        { text: "Código Asignatura", value: "codigoAsignatura" },
        { text: "Fecha de Ingreso", value: "createdAt" },
        { text: "Acciones", value: "acciones", sortable: false }
      ],
      inquietudes: [],
      inquietudesProtocolo: [],
      nombreBoton: "Ver Listado Inquietudes Protocolo Académico",
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") }
      }
    };
  },

  created() {
    this.axios
      .get(
        process.env.VUE_APP_BASE_URL +
          "/solicitudVrae/getByRun?run=" +
          this.$session.getAll().idUser,
        this.auth
      )
      .then(response => {
        if(response.data != "")
          this.inquietudes = response.data;
        else
          this.inquietudes = [] 
      })
      .catch(error => {
        alert(error);
      });

    this.axios
      .get(
        process.env.VUE_APP_BASE_URL +
          "/solicitudProtocolo/getByRun?run=" +
          this.$session.getAll().idUser,
        this.auth
      )
      .then(response => {
        if(response.data != "")
          this.inquietudesProtocolo = response.data;
        else
        this.inquietudesProtocolo = [] 
      })
      .catch(error => {
        alert(error);
      });
  },
  methods: {
    receiveData(event) {
      this.inquietudes = event;
    },
    cambiarBoton() {
      this.vrae = !this.vrae;
      this.vrae
        ? (this.nombreBoton = "Ver Listado Inquietudes Protocolo Académico")
        : (this.nombreBoton = "Ver Listado Inquietudes VRAE");
    }
  }
};
</script>

<style>
.c-orange {
  color: rgb(255, 153, 0);
}

@media only screen and (min-width: 900px) {
  .hidden1{
    display: none !important;
  }
}
@media only screen and (max-width: 899px) {
  .hidden2 {
    display: none !important;
  }
}
</style>