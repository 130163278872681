<template>
  <v-container>
    <h1>Formulario de inquietud para Docente</h1>
    <v-row>
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header style="color:white" color="#071D49">Datos de Docente</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    persistent-hint
                    readonly
                    label="Nombres"
                    outlined
                    dense
                    v-model="profesor.nombre"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    persistent-hint
                    readonly
                    label="Apellido Paterno"
                    outlined
                    dense
                    v-model="profesor.apellido1"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    persistent-hint
                    readonly
                    label="Apellido Materno"
                    outlined
                    dense
                    v-model="profesor.apellido2"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    persistent-hint
                    readonly
                    label="Correo Electrónico"
                    outlined
                    dense
                    v-model="profesor.email"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-form v-model="valid" :lazy-validation="lazy">
      
      <v-row>
        <v-col cols="12">
          <v-select
            return-object
            :rules="requiredRules"
            :items="asignaturas"
            item-text="nombreAsignatura"
            outlined
            dense
            hint="Selecciona Nombre Asignatura"
            persistent-hint
            label="Asignaturas *"
            v-model="asignatura"
          ></v-select>
        </v-col>
      </v-row>
      <!-- ∫ -->
      <v-row>
        <v-col cols="12">
          <v-textarea
            hint="Ingresa el detalle de tu inquietud"
            :rules="requiredRules"
            persistent-hint
            outlined
            counter
            no-resize
            label="Detalle *"
            v-model="detalle"
          ></v-textarea>
        </v-col>
      </v-row>
      <!--   <v-row>
      <v-col cols="12" >
        <v-file-input
          v-model="files"
          placeholder="Selecciona un archivo"
          label="Subir Archivo"
          outlined
          prepend-icon="mdi-paperclip"
        >
          <template v-slot:selection="{ text }">
            <v-chip small label color="primary">{{ text }}</v-chip>
          </template>
        </v-file-input>
      </v-col>
      </v-row>-->
      <v-row>
        <v-col cols="12">
          <v-row justify="end">
            <v-btn
              :loading="loading"
              style="margin-bottom:30px"
              :disabled="!valid"
              color="warning"
              @click="enviarInquietud()"
              light
            >Enviar</v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      asignaturas: [],
      panel: true,
      files: [],
      profesor: {},
      profesor: "",
      asignatura: "",
      detalle: "",
      deshabilitado: true,
      carrera: {},
      profesor: {
        carrera: {}
      },
      requiredRules: [v => !!v || "Este campo es requerido"],
      lazy: false,
      valid: false,
      loading: false,
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") }
      }
    };
  },

  created() {
    this.axios
      .get(
        process.env.VUE_APP_BASE_URL +
          "/profesor/getProfesorById?id=" +
          this.$session.getAll().idUser,
        this.auth
      )
      .then(response => {
        this.profesor = response.data;
      })
      .catch(error => {
        alert(error);
      })
      .then(response => {
        this.axios
          .get(
            process.env.VUE_APP_BASE_URL +
              "/trabajo/getByProfesorId?id=" +
              this.$session.getAll().idUser,
            this.auth
          )
          .then(response => {
            this.asignaturas = response.data;
          })
          .catch(error => {
            alert(error);
          });
      });
  },

  methods: {
    enviarInquietud() {
      this.loading = true;
      const solicitud = {
        ccNombre: this.asignatura.ccNombre,
        ccValor: this.asignatura.ccValor,
        codigoPlan: this.asignatura.codigoPlan,
        nombreAsignatura: this.asignatura.nombreAsignatura,
        codigoAsignatura: this.asignatura.codigoAsignatura,
        detalle: this.detalle
      };
      this.axios
        .post(
          process.env.VUE_APP_BASE_URL +
            "/solicitudProfesor/createSolicitudProfesor?id=" +
            this.$session.getAll().idUser,
          solicitud,
          this.auth
        )
        .then(response => {
          var elements = response.data
          alert("Inquietud Enviada a estos correos: " + elements.join());
          this.loading = false;
          this.$router.push("/menu");
        })
        .catch(error => {
          alert(error);
          this.loading = false;
        });
    }
  }
};
</script>