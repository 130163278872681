<template>
  <div>
    <h1>Listado de Inquietudes</h1>
    <v-spacer />
    <TableAllInfo
      @send-data="receiveData"
      :headers="headersAcademico"
      :nameTitle="'Inquietudes de Protocolo Académico'"
      :inquietudes="inquietudesProtocolo"
      :vrae="false"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import TableAllInfo from "@/components/TableCrudAllInfo.vue";
export default {
  name: "Funcionarios",
  components: {
    TableAllInfo
  },
  data() {
    return {
      loading: "cargando",
      itemType: "Proceso",
      headersAcademico: [
        { text: "Estudiante", value: "alumno.nombre" },
        { text: "Apellido Paterno", value: "alumno.apellido1" },
        { text: "Apellido Materno", value: "alumno.apellido2" },
        { text: "Asignatura", value: "nombreAsignatura" },
        { text: "Profesor", value: "profesor" },
        { text: "Carrera", value: "alumno.carrera.nombre" },
        { text: "Fecha de Ingreso", value: "createdAt" },
        { text: "Acciones", value: "acciones", sortable: false }
      ],
      inquietudesProtocolo: [],
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") }
      }
    };
  },

  created() {
    this.axios
      .get(process.env.VUE_APP_BASE_URL + "/solicitudProtocolo/", this.auth)
      .then(response => {
        this.inquietudesProtocolo = response.data;
      })
      .catch(error => {
        alert(error);
      });
  },
  methods: {
    receiveData(event) {
      this.inquietudes = event;
    }
  }
};
</script>

<style>
.c-orange {
  color: rgb(255, 153, 0);
}
</style>