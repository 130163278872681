import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/layout component/Login.vue'
import Layout from '../components/layout component/Layout.vue'

import Processes from '../views/Processes.vue'
import ProrrectorListaP from '../views/ProrrectorLista.vue'
import ProrrectorListaV from '../views/ProrrectorLista.vue'
import ProrrectorListaD from '../views/ProrrectorLista.vue'
import ProfesorLista from '../views/ProfesorList.vue'


import VraeLista from '../views/VraeLista.vue'
import ProtocoloVistaCc from '../views/ProtocoloVistaCc.vue'
import Mensaje from '../components/MensajeContacto.vue'
import InquietudProtocolo from '../components/ContactoInquietudProtocolo.vue'
import InquietudProfesor from '../components/ContactoInquietudProfesor.vue'

import MenuJF from '../components/MenuJF'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' },
  ,
  {
    path: '/login',
    name: 'login',
    component: Login,
  },

  {
    path: '/layout',
    name: 'layout',
    component: Layout,
    redirect: '/menu',
    children: [{
      path: '/lista-inquietudes',
      name: 'Processes',
      component: Processes,
      beforeEnter: (to, from, next) => {
        if (router.app.$session.getAll().typeUser == 'Alumno') next()
        else next({ name: 'menu' })
      }
    }
    ,
    {
      path: '/inquietud-conectividad',
      name: 'InquietudConectividad',
      component: Mensaje,
      beforeEnter: (to, from, next) => {
        if (router.app.$session.getAll().typeUser == 'Alumno') next()
        else next({ name: 'menu' })
      }
    },
    {
      path: '/inquietud-protocolo',
      name: 'InquietudProtocolo',
      component: InquietudProtocolo,
      beforeEnter: (to, from, next) => {
        if (router.app.$session.getAll().typeUser == 'Alumno') next()
        else next({ name: 'menu' })
      }
    },
    {
      path: '/lista-inquietudes-prorrector-p',
      name: 'ProrrectorListaP',
      component: ProrrectorListaP,
      beforeEnter: (to, from, next) => {
        if (router.app.$session.getAll().typeUser == 'Prorrector') next()
        else next({ name: 'menu' })
      }
    },

    {
      path: '/lista-inquietudes-prorrector-v',
      name: 'ProrrectorListaV',
      component: ProrrectorListaV,
      beforeEnter: (to, from, next) => {
        if (router.app.$session.getAll().typeUser == 'Prorrector') next()
        else next({ name: 'menu' })
      }
    },

    {
      path: '/lista-inquietudes-prorrector-d',
      name: 'ProrrectorListaD',
      component: ProrrectorListaD,
      beforeEnter: (to, from, next) => {
        if (router.app.$session.getAll().typeUser == 'Prorrector') next()
        else next({ name: 'menu' })
      }
    },
    {
      path: '/lista-inquietudes-vrae',
      name: 'VraeLista',
      component: VraeLista
    },
    {
      path: '/lista-inquietudes-profesor',
      name: 'profesor-lista',
      component: ProfesorLista,
      beforeEnter: (to, from, next) => {
        if (router.app.$session.getAll().typeUser == 'Profesor') next()
        else next({ name: 'menu' })
      }
    },

    {
      path: '/inquietud-profesor',
      name: 'InquietudProfesor',
      component: InquietudProfesor,
      beforeEnter: (to, from, next) => {
        if (router.app.$session.getAll().typeUser == 'Profesor') next()
        else next({ name: 'menu' })
      }
    },

    {
      path: '/lista-inquietudes-protocolo',
      name: 'ProtocoloVistaCc',
      component: ProtocoloVistaCc
    },
    {
      path: '/menu',
      name: 'menu',
      component: MenuJF,
    }
    ]
  }


]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !router.app.$session.exists()) next({ name: 'login' })
  else next()
})

export default router
