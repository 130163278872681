<template>
  <div>
    <v-row>
      <v-col cols="6">
        <h1>Listado de inquietudes</h1>
      </v-col>
      <v-col cols="6" align="end">
        <v-btn
          :loading="loading"
          color="blue"
          dark
          @click="descargarConsolidado()"
        >Descargar Consolidado de Información</v-btn>
      </v-col>
    </v-row>
    <v-spacer />
    <TableAllInfo
      v-if="vrae && !profesor"
      @send-data="receiveData"
      :headers="headersVrae"
      :nameTitle="'Inquietudes de VRAE'"
      :inquietudes="inquietudes"
      :vrae="true"
    />

    <v-spacer />
    <TableAllInfo
      v-if="!vrae && !profesor"
      @send-data="receiveData"
      :nameTitle="'Inquietudes de Protocolo Académico'"
      :headers="headersAcademico"
      :inquietudes="inquietudesProtocolo"
      :vrae="false"
    />
    <v-spacer />
    <TableCrudProfesor
      v-if="profesor"
      @send-data="receiveData"
      :headers="headersProfe"
      :nameTitle="'Inquietudes Docentes'"
      :inquietudes="inquietudes"
      :profe="false"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import TableAllInfo from "@/components/TableCrudAllInfo.vue";
import TableCrudProfesor from "@/components/TableCrudProfesor.vue";
export default {
  name: "Funcionarios",
  components: {
    TableAllInfo,
    TableCrudProfesor
  },
  data() {
    return {
      loading: false,
      itemType: "Proceso",
      headersProfe: [
        { text: "Nombre", value: "profesor.nombre" },
        { text: "Apellido P", value: "profesor.apellido1" },
        { text: "Apellido M", value: "profesor.apellido2" },
        { text: "Centro de Costo", value: "ccNombre" },
        { text: "Fecha de Ingreso", value: "createdAt" },
        { text: "Acciones", value: "acciones", sortable: false }
      ],
      headersVrae: [
        { text: "Estudiante", value: "alumno.nombre" },
        { text: "Apellido Paterno", value: "alumno.apellido1" },
        { text: "Apellido Materno", value: "alumno.apellido2" },
        { text: "Carrera", value: "alumno.carrera.nombre" },
        { text: "Inquietud", value: "inquietud" },
        { text: "Fecha de Ingreso", value: "createdAt" },
        { text: "Acciones", value: "acciones", sortable: false }
      ],

      headersAcademico: [
        { text: "Estudiante", value: "alumno.nombre" },
        { text: "Apellido Paterno", value: "alumno.apellido1" },
        { text: "Apellido Materno", value: "alumno.apellido2" },
        { text: "Asignatura", value: "nombreAsignatura" },
        { text: "Carrera", value: "alumno.carrera.nombre" },
        { text: "Fecha de Ingreso", value: "createdAt" },
        { text: "Acciones", value: "acciones", sortable: false }
      ],
      inquietudes: [],
      inquietudesProtocolo: [],
      vrae: false,
      profesor: false,
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") }
      }
    };
  },

  created() {
    //validar que solo el pro pueda entrar a estas vistas*
    if (this.$route.fullPath == "/lista-inquietudes-prorrector-v") {
      this.vrae = true;
      this.axios
        .get(process.env.VUE_APP_BASE_URL + "/solicitudVrae/", this.auth)
        .then(response => {
          this.inquietudes = response.data;
        })
        .catch(error => {
          alert(error);
        });
    }

    if (this.$route.fullPath == "/lista-inquietudes-prorrector-p") {
      this.vrae = false;
      this.axios
        .get(process.env.VUE_APP_BASE_URL + "/solicitudProtocolo/", this.auth)
        .then(response => {
          this.inquietudesProtocolo = response.data;
        })
        .catch(error => {
          alert(error);
        });
    }

    if (this.$route.fullPath == "/lista-inquietudes-prorrector-d") {
      this.profesor = true;
      this.axios
        .get(process.env.VUE_APP_BASE_URL + "/solicitudProfesor/", this.auth)
        .then(response => {
          this.inquietudes = response.data;
        })
        .catch(error => {
          alert(error);
        });
    }
  },
  methods: {
    receiveData(event) {
      this.inquietudes = event;
    },
    descargarConsolidado() {
      this.loading = true;
      var endpoint = "";
      var filename = "";
      if (this.vrae && !this.profesor) {
        endpoint = "/export/allSolicitudesVrae";
        filename = "Inquietudes_VRAE";
      } else if (!this.vrae && !this.profesor) {
        endpoint = "/export/allSolicitudesProtocolo";
        filename = "Inquietudes_Protocolo_Académico";
      } else if (this.profesor) {
        endpoint = "/export/allSolicitudesProfesor";
        filename = "Inquietudes_Docentes";
      }

      if (endpoint != "") {
        this.axios
          .get(process.env.VUE_APP_BASE_URL + endpoint, {
            responseType: "arraybuffer",
            headers: { Authorization: "" + this.$session.get("jwt") }
          })
          .then(response => {
            this.forceFileDownload(response, filename);
            this.loading = false;
          })
          .catch(error => {
            alert(error);
            this.loading = false;
          });
      }
    },
    forceFileDownload(response, tipo) {
      //console.log("Aqui va el header");
      //console.log(response.headers);
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: response.headers["content-type"] })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", tipo + ".xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    }
  }
};
</script>

<style>
.c-orange {
  color: rgb(255, 153, 0);
}
</style>