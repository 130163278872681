<template>
  <v-content class="content">
    <v-row justify="center">
      <v-col
        v-for="(n, index) in items"
        :key="index"
        cols="12"
        :sm="n.class"
        :md="n.class"
        :lg="n.class"
      >
        <v-card flat tile class="d-flex">
          <div class="contenedor">
            <v-img :src="n.src" aspect-ratio="1" class="image">
              <template v-slot:placeholder>
                <v-row justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-card style="background-color: #071D49;" class="image fondo">
              <p style>{{n.name}}</p>
            </v-card>

            <div class="middle">
              <v-col cols="12">
                <v-row justify="center">
                  <v-btn :aria-label="n.name" :to="{path: n.path}" dark color="#071D49">Ingresar</v-btn>
                </v-row>
              </v-col>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-content>
</template>

<script>
import img1 from "../assets/house.png";
import img2 from "../assets/book.png";
import img3 from "../assets/clipboard.png";

export default {
  data: () => ({
    //TODO: cambiar en base tipo de usuario
    pro: "",
    items: [],
    itemsEstudiante: [
      {
        name: "Ingrese una inquietud económica o de conectividad",
        src: img1,
        path: "/inquietud-conectividad",
        pro: false,
        class: 4,
      },
      {
        name: "Ingrese inquietud de Protocolo Académico",
        src: img2,
        path: "/inquietud-protocolo",
        pro: false,
        class: 4
      },
      {
        name: "Ver mis inquietudes",
        src: img3,
        path: "/lista-inquietudes",
        pro: false,
        class: 4
      }
    ],
    itemsPro: [
      {
        name: "Ver lista de inquietudes VRAE",
        src: img1,
        path: "/lista-inquietudes-prorrector-v",
        pro: true,
        class: 4
      },
      {
        name: "Ver lista de inquietudes de Protocolo Académico",
        src: img2,
        path: "/lista-inquietudes-prorrector-p",
        pro: true,
        class: 4
      },
      {
        name: "Ver lista de inquietudes de Docentes",
        src: img3,
        path: "/lista-inquietudes-prorrector-d",
        pro: true,
        class: 4
      }
    ],
    itemsDocente: [
      {
        name: "Ingrese una inquietud ",
        src: img1,
        path: "/inquietud-profesor",
        pro: false,
        class: 4
      },
      {
        name: "Ver mis inquietudes",
        src: img3,
        path: "/lista-inquietudes-profesor",
        pro: false,
        class: 4
      }
    ]
  }),
  created() {
    //TODO BORRAR
    if (this.$session.getAll().typeUser == "Prorrector")
      this.items = this.itemsPro;
    if (this.$session.getAll().typeUser == "Alumno")
      this.items = this.itemsEstudiante;
    if (this.$session.getAll().typeUser == "Profesor")
      this.items = this.itemsDocente;
  }
};
</script>
<style scoped>
.contenedor:hover .middle {
  opacity: 1;
}

.contenedor:focus-within .middle{
  opacity: 1;
}
.image {
  display: block;
  width: 70%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.middle {
  width: 50% !important;
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.contenedor {
  width: 100%;
  padding: 0;
}

.fondo {
  margin-top: 5px;
  text-align: center;
  color: white;
  justify-content: center;
  align-content: center;
}

.contenedor:hover .image {
  opacity: 0.3;
}
.content {
  margin-top: 15vh;
  margin-bottom: 15vh;
}
</style>