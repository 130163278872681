<template>
  <v-app-bar fixed color="#071D49" class="nav mx-btn" height="90">
    <v-app-bar-nav-icon @click.stop="drawer = !drawer" color="white" class="md-hidden"></v-app-bar-nav-icon>
    <v-navigation-drawer v-model="drawer" temporary app absolute>
      <v-list flat>
        <v-list-item-group v-model="group" color="deep-orange">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="title">Hola Usuario</v-list-item-title>
              <v-list-item-subtitle>perfil</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item :to="{name: 'menu'}">
            <v-list-item-title>Inicio</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-btn depressed @click="logout()" text color="black" light>Cerrar sesión</v-btn>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <img
      class="img-fix"
      alt="Logo universidad de Santiago de Chile"
      :src="img"
      width="140"
      height="70"
      style="margin-right:20px"
    />

    <v-toolbar-title class="text-white">USACH TE APOYA</v-toolbar-title>

    <v-spacer></v-spacer>
    <div class="text-center">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="indigo" dark v-bind="attrs" v-on="on">
              Contacto
            </v-btn>
          </template>

          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-action>
                  <img width="100" :src="logo_usach2" alt="John" />
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>Prorrectoría</v-list-item-title>
                  <v-list-item-subtitle
                    >Contacto de Plataforma</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-list>
              <v-list-item>
                <v-list-item-action>
                  <v-icon right>mdi-email</v-icon>
                </v-list-item-action>
                <v-list-item-title>contacto.usachteapoya@usach.cl</v-list-item-title>
              </v-list-item>
            </v-list>

            <v-list-item>
              <v-list-item-action>
                <v-icon right>mdi-youtube</v-icon>
              </v-list-item-action>
              <v-list-item-title
                ><a
                  href="https://www.youtube.com/watch?v=P83ShddfsUg&feature=youtu.be"
                  target="_blank"
                  >Instructivo perfil Docente</a
                ></v-list-item-title
              >
            </v-list-item>

            <v-list-item>
              <v-list-item-action>
                <v-icon right>mdi-youtube</v-icon>
              </v-list-item-action>
              <v-list-item-title
                ><a
                  href="https://www.youtube.com/watch?v=qRxlf9WC7Zw&feature=youtu.be"
                  target="_blank"
                  >Instructivo perfil Estudiante</a
                ></v-list-item-title
              >
            </v-list-item>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn text @click="menu = false"> Cerrar </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>
    <div class="divider"/>

    <v-btn
      aria-label="Volver al menú principal"
      depressed
      :to="{name: 'menu'}"
      text
      color="white"
      class="sm-hidden"
    >Menu Inicio</v-btn>

    <v-btn @click="resizeDown()" dark icon title="Disminuir tamaño de letra" class="sm-hidden mr-1">
      <v-icon>mdi-format-font-size-decrease</v-icon>
    </v-btn>

    <v-btn @click="resizeUp()" dark icon title="Aumentar tamaño de letra" class="sm-hidden mr-1">
      <v-icon>mdi-format-font-size-increase</v-icon>
    </v-btn>

    <v-menu bottom left :offset-y="offset">
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on" title="Opciones de Usuario" class="sm-hidden mr-1">
          <v-icon>mdi-account-circle</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item>
          <v-btn depressed @click="logout()" text color="black" light>Cerrar sesión</v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import img from "../../assets/LOGO USACH.png";
import logo_usach2 from "../../assets/logo_usach2.jpg";

export default {
  name: "Navbar",
  data: () => ({
    menu: false,
    logo_usach2: logo_usach2,
    img: img,
    offset: true,
    drawer: false,
    group: null,
    sidebarItems: [
      {
        icon: "mdi-wifi",
        text: "Funcionarios"
      },
      {
        icon: "mdi-bluetooth",
        text: "Procesos"
      },
      {
        icon: "mdi-chart-donut",
        text: "Configuración"
      }
    ]
  }),
  watch: {
    group() {
      this.drawer = false;
    }
  },

  methods: {
    logout() {
      this.$session.destroy();
      this.$router.push("/");
    },
    resizeUp() {
      var resize = this.$session.getAll().resize;
      this.$session.set("resize", resize + 1);
      function changeFont(element) {
        var style = window
          .getComputedStyle(element, null)
          .getPropertyValue("font-size");
        var fontSize = parseFloat(style);
        element.style.fontSize = fontSize + 2 + "px";
        for (var i = 0; i < element.children.length; i++) {
          changeFont(element.children[i]);
        }
      }
      changeFont(document.getElementsByTagName("body")[0]);
    },
    colorChange() {
      function colorChange(element) {
        var style = window
          .getComputedStyle(element, null)
          .getPropertyValue("color");

        //console.log(style);
        if (
          element.style.backgroundColor == "rgb(235, 119, 4)" ||
          element.style.backgroundColor == "#071D49"
        ) {
          console.log("entre");
          element.style.backgroundColor = "black";
        }

        for (var i = 0; i < element.children.length; i++) {
          colorChange(element.children[i]);
        }
      }
      colorChange(document.getElementsByTagName("body")[0]);
    },
    resizeDown() {
      var resize = this.$session.getAll().resize;
      if (this.$session.getAll().resize > 0)
        this.$session.set("resize", resize - 1);
      function changeFont(element) {
        var style = window
          .getComputedStyle(element, null)
          .getPropertyValue("font-size");
        var fontSize = parseFloat(style);
        element.style.fontSize = fontSize - 2 + "px";
        for (var i = 0; i < element.children.length; i++) {
          changeFont(element.children[i]);
        }
      }
      changeFont(document.getElementsByTagName("body")[0]);
    }
  }
};
</script>
<style>
.v-list-item-title {
  cursor: pointer;
}
.text-white {
  color: white;
}
@media only screen and (min-width: 600px) {
  .md-hidden {
    display: none !important;
  }
}
@media only screen and (max-width: 599px) {
  .sm-hidden {
    display: none !important;
  }
}
.divider{
    width:5px;
    height:auto;
    display:inline-block;
}
</style>